@font-face {
  font-family: "Cherry Bomb-Regular";
  src: url("./src/fonts/CherryBombOne-Regular.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --carouselwhite: rgb(255, 255, 255);
}

body {
  margin: 0;
  line-height: normal;
}
