@font-face {
  font-family: Cherry Bomb-Regular;
  src: url("CherryBombOne-Regular.2beb37ed.ttf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

:root {
  --carouselwhite: #fff;
}

body {
  margin: 0;
  line-height: normal;
}
/*# sourceMappingURL=index.33bf81fb.css.map */
